








































































import { Vue, Component } from 'vue-property-decorator';
import Workspaces from "@/state/Workspaces";
import SalesTaxService from "@/services/SalesTaxService";
import SalesTaxPermitDTO from "@/dto/salestax/SalesTaxPermitDTO";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import CreateOrUpdateSalesTaxPermitRequestDTO from "@/dto/salestax/CreateOrUpdateSalesTaxPermitRequestDTO";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import InMemoryFileUpload from "@/components/common/InMemoryFileUpload.vue";
import FileService from "@/services/FileService";

const AppModule = namespace("App");

@Component({
	components: {InMemoryFileUpload}
})
export default class SalesTaxPermitList extends Vue {

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private message = "";
	private successful = true;

	private permits: Array<SalesTaxPermitDTO> = [];

	private addMode = false;

	private newPermitRequest = new CreateOrUpdateSalesTaxPermitRequestDTO();

	private newRegistrationDocument!: File | null;

	mounted(){
		this.addMode = false;
		this.message = "";
		this.loadPermits();
	}

	loadPermits(){
		this.startLoading();
		return SalesTaxService.getSalesTaxPermits(Workspaces.getCurrent!.id).then(
			response => {
				this.permits = response.data;
				this.stopLoading();
			},
			error => {
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				this.stopLoading();
			}
		);
	}

	showLoadDocumentModal(p: SalesTaxPermitDTO){
		this.$modal.show(
			FileUpload,
			{
				multiple: false,
				userId: Workspaces.getCurrent?.userId,
				onUploaded: (files: FileMetaDTO[]) => this.addFilesToExistPermit(files, p)
			}
		)
	}

	private addFilesToExistPermit(files: FileMetaDTO[], p: SalesTaxPermitDTO): void {
		this.newPermitRequest.registrationDocumentId = files[0].id;
		this.newPermitRequest.state = p.state;
		this.newPermitRequest.companyId = p.companyId;
		this.newPermitRequest.registrationDate = p.registrationDate
		if(p) {
			this.message = "";
			this.startLoading();
			SalesTaxService.editSalesTaxPermit(p.id, this.newPermitRequest).then(
				response => {
					this.successful = true;
					this.addMode = false;
					this.loadPermits();
					this.newPermitRequest = new CreateOrUpdateSalesTaxPermitRequestDTO();
				},
				error => {
					this.successful = false;
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
					this.stopLoading();
				}
			);
		}
		this.$modal.hideAll();
	}

	private onFilesAdd(file: File): void {
		this.newRegistrationDocument = file;
	}


	private createPermit(): void{
		this.newPermitRequest.companyId = Workspaces.getCurrent?.id;
		this.message = "";
		if (this.newRegistrationDocument) {
			this.startLoading();
			FileService.uploadFile(this.newRegistrationDocument, Workspaces.getCurrent.userId, null).then(
				res => {
					this.newPermitRequest.registrationDocumentId = res.data.id;
					this.createNewPermit();
					this.newRegistrationDocument = null;
				},
				err => {
					this.successful = false;
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
					this.stopLoading();
				}
			)
		} else {
			this.createNewPermit();
		}

	}

	private createNewPermit() {
		SalesTaxService.createSalesTaxPermit(this.newPermitRequest).then(
			() => {
				this.successful = true;
				this.addMode = false;
				this.loadPermits();
				this.newPermitRequest = new CreateOrUpdateSalesTaxPermitRequestDTO();
			},
			error => {
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				this.stopLoading();
			}
		)
	}
}
